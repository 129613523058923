<template>
    <div v-if="!discussionView" class="question-builder-summary primaryShadow my-3 py-4 px-6 " :class="{'discussion-view' : discussionView}">
        <div class="d-flex mb-4 header-title" :class="{'align-center' : isMobile}">
            <v-btn v-if="canEdit" color="primary" icon @click="$eventBus.$emit('actionClick', tag)">
                <v-icon>$edit</v-icon>
            </v-btn>
            <span class="align-self-center primary-color text-h5 custom-ml-6 question-header font-weight-bold">{{ removeColons(questionContent) }}</span>
            <v-btn v-if="hasExpandFeature" color="primary"  text @click="toggleQuestion()">{{ toggleBtnText }}</v-btn>
            <v-spacer></v-spacer>
            <span>{{ questionCountText }}</span>
        </div>
        <div v-html="value" class="dynamic-value"></div>
    </div>
    <div v-else-if="questionCount" class="question-builder-summary discussion-view primaryShadow pa-0" :class="{'pdf-screen' : !showExpandBtn}">
        <div class="d-flex  header-title">
            <span class="align-self-center black--text font-weight-bold"> {{removeColons(questionContent)}} ({{ questionCount > 0 ? questionCount : '' }})</span>
            <v-btn color="primary" v-if="csrHold" icon @click="$emit('open-question-builder')">
                <v-icon small>$edit</v-icon>
            </v-btn>
            <v-btn color="primary" v-if="showExpandBtn && hasExpandFeature" text @click="toggleQuestion(isTranslatedBuilder, isOriginalBuilder)">{{ toggleBtnText }}</v-btn>
        </div>
        <div v-html="value"></div>
    </div>
</template>

<script lang="ts">
import Vue from "vue";

import OSheet from "@/framework/OSheet.vue";


export default Vue.extend({
    components: {
        OSheet
    },
    props: {
        tag: {
            type: String,
            required: true
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        draftId: {
            type: Number,
            required: false
        },
        value: {
            type: String,
            required: true
        },
        questionCount: {
            type: Number,
            default: 0
        },
        discussionView: {
            type: Boolean,
            default: false
        },
        csrHold: {
            type: Boolean,
            default: true
        },
        showExpandBtn: {
            type: Boolean,
            default: true
        },
        isTranslatedBuilder: {
            type: Boolean,
            default: false
        },
        isOriginalBuilder: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isMobile(): boolean {
            // True when the viewport is under the breakpoint for mobile behavior
            return this.$vuetify.breakpoint.smAndDown;
        },
        questionText(): string {
            return this.questionCount > 1 ? 'Questions' : 'Question';
        }
    },
    data: () => ({
        summaryChoicesEl: null as NodeListOf<HTMLElement> | null,
        questionContent: '',
        toggleBtnText: '',
        questionCountText: '',
        toggleBtnExpandText: '',
        toggleBtnCollpaseText: '',
        hasExpandFeature: false
    }),
    methods: {
        fetchHeaderContent() {
            const builderClass = this.isTranslatedBuilder ? 'translated-builder' :
                this.isOriginalBuilder ? 'original-builder' :
                    'question-builder-summary';

            const questionEl = document.querySelector(`.${builderClass} .wizard-question-label`) as HTMLElement | null;
            const expandEl = document.querySelector(`.${builderClass} .wizard-question-expand`) as HTMLElement | null;
            const collapseEl = document.querySelector(`.${builderClass} .wizard-question-collapse`) as HTMLElement | null;
            const countEl = document.querySelector(`.${builderClass} .wizard-question-count`) as HTMLElement | null;

            if (questionEl) {
                this.questionContent = questionEl.innerText;
            }
            if (expandEl) {
                this.hasExpandFeature  = expandEl.innerText.length > 1;
                this.toggleBtnText = expandEl.innerText;
                this.toggleBtnExpandText = expandEl.innerText;
            }
            if (countEl) {
                this.questionCountText = countEl.innerText;
            }
            if (collapseEl) {
                this.toggleBtnCollpaseText = collapseEl.innerHTML;
            }
        }
        ,
        toggleQuestion(isTranslatedBuilder?: boolean | undefined | null, isOriginalBuilder?: boolean | undefined | null,  isPdfData?: boolean|undefined) {
            if(isTranslatedBuilder) {
                this.summaryChoicesEl = document.querySelectorAll('.translated-builder .question-builder-summary .collapse-choices') as NodeListOf<HTMLElement>;
            } else if (isOriginalBuilder) {
                this.summaryChoicesEl = document.querySelectorAll('.original-builder .question-builder-summary .collapse-choices') as NodeListOf<HTMLElement>;
            } else {
                this.summaryChoicesEl = document.querySelectorAll('.question-builder-summary .collapse-choices') as NodeListOf<HTMLElement>;
            }

            if(isPdfData) {
                this.summaryChoicesEl = document.querySelectorAll('.pdf-screen .collapse-choices') as NodeListOf<HTMLElement>;
            }

            const currentStyle: string = this.summaryChoicesEl.length > 0 ? this.summaryChoicesEl[0].style.display : 'none';

            if (currentStyle === 'none' || currentStyle === '') {
                for (let choiceEl of this.summaryChoicesEl) {
                    choiceEl.style.display = 'block';
                }

                this.toggleBtnText = this.toggleBtnCollpaseText ?? 'Collapse All';

            } else {
                for (let choiceEl of this.summaryChoicesEl) {
                    choiceEl.style.display = 'none';
                }
                this.toggleBtnText = this.toggleBtnExpandText ?? 'Expand All';

            }
        },
        removeColons(input: string): string {
            return input.replace(/:/g, '');
        }
    },
    watch: {
        value(newVal) {
            if(newVal) {
                this.$nextTick(() => this.fetchHeaderContent());
            }
        }
    },
    mounted() {
        this.fetchHeaderContent();
    }
});
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
  display: absolute;
  right: 8px
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
.capitalize {
    text-transform: capitalize;
}

.review-item-val {
    justify-content: flex-end;
    display: flex
}

.question-builder-summary {
    background-color: #ffffff;
    box-shadow: 0px 3px 15px #B5B5B5;
    border-radius: 10px;
    max-width: 600px;
}

.primary-color {
    color: var(--v-primary-base);
}

.custom-ml-6 {
    margin-left: 6px;
}
</style>
<!-- global styling to handle dynamic elements -->
<style lang="scss">
.pdf-screen {
    margin-top: 10px;
    
    .collapse-choices {
        display: block !important;
    }
}
.question-builder-summary {

    .wizard-question-expand,
    .wizard-question-count,
    .wizard-question-label,
    .wizard-question-collapse {
        display: none;
    }

    li {
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: 500;

    }

    li:has(p) {
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 500;

        p {
            margin-top: 10px;
            max-width: 100%;
            word-wrap: break-word; 
        }
    }
    

    .collapse-choices {
        display: none;

        li {
            font-weight: 500;
            color: var(--v-primary-base);
        }
    }
}

.dynamic-value {
    padding-left: 35px;
}

.question-header {
    font-size: 18px;
    font-weight: 500;
}

.question-builder-summary {
    p,li {
         font-size: 18px !important;
         font-weight: 500;
    }

    ul.collapse-choices {
            list-style-type: disc !important;
        }

    .collapse-choices {
            p,li {
                font-size: 14px !important;
            }
}
}

.discussion-view.question-builder-summary.pdf-screen {
    max-height: unset !important;
}

.discussion-view.question-builder-summary {
    background-color: #ffffff;
    box-shadow: none;
    border-radius: 0;
    max-width: 100%;
    max-height: 400px;
    overflow-y: auto;

    p,li {
        font-size: 12px !important;
        margin-bottom: 10px !important;
        font-weight: bold;

        .collapse-choices {
            p,li {
                font-weight: 400;
            }
        } 
    }
}

@media (max-width: 960px) {
    .discussion-view.question-builder-summary {
        max-height: unset;
        overflow-y: unset;
    }
}
</style>