<template>
	<validation-observer ref="observer" v-slot="{ invalid, changed }">
    <v-form class="builder-submission-form">
        <p class="black--text pb-2">{{ $t('messages.questionCaption') }}</p>
        <v-textarea :class="{ 'error-text': showTextError }"
            :label="showTextError ? $t('placeholders.requiredError') : $t('placeholders.questionBuilder')"
            class="question-input-field mb-3" rows="2" no-resize v-model="questionText" @focusout="updateQuestionText()"
            outlined></v-textarea>
        <div class="response-container d-flex">
            <p v-if="!isMobile" class="d-flex align-center black--text mb-0 font-weight-bold" :class="{ 'error-text': showTypeError }">{{
            $t('messages.questionResponse') }}</p>
            <v-spacer v-if="!isMobile"></v-spacer>
            <v-select @change="updateValidationChoice()" :color="showTypeError ? 'error' : ''"
                class="question-type ms-md-3" v-model="selectedOption" clearable
                :label="showTypeError ? $t('placeholders.requiredError') : $t('placeholders.questionType')"
                :items="configItemsText" :class="{ 'error-text': showTypeError }"></v-select>
        </div>
        <v-list  v-if="selectedOption" class="pa-0" :class="{'mb-4' : isMobile}">
            <v-list-group no-action>
                <template v-slot:activator>
                    <v-list-item-title>View Question Options</v-list-item-title>
                </template>
                <v-list-item class="ps-4 validation-list" v-for="(value, key) in validationChoices" :key="key">
                    <v-list-item-title class="d-flex align-center" v-if="validationChoices[key]">
                        <span>{{ sanitizeValue(key) }}</span>
                        <v-spacer></v-spacer>
                        <v-switch @click="getConfigData()" v-model="validationSettings[key]"></v-switch>
                    </v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
        <div class="question-content mt-5" v-if="showChoices">
            <v-row v-for="(choice, index) in choices" :key="index" class="question-content-list" :class="{'pt-2' : isMobile}">
                <v-col cols="4" v-if="!isMobile" class="align-self-center">
                    <p class="text-subtitle-1 mb-0 pb-2 ">{{ `What is Choice ${index + 1}` }}</p>
                </v-col>
                <v-col  cols="12" md="8" class="d-flex">
                    <v-text-field v-model="choice.text" @blur="getConfigData()" :label="`Add Choice ${index + 1}`" :ref="`choice-${index}`" dense></v-text-field>
                    <v-btn icon @click="reduceChoice(index)"><v-icon color="error" size="small">$trash</v-icon></v-btn>
                </v-col>
            </v-row>
            <div class="question-action" :class="{'text-center' : isMobile}">
                <v-btn color="primary" text @click="addChoice()">ADD CHOICE</v-btn>
            </div>
        </div>
        <div class="question-content-rating mt-5" v-if="showRating">
            <p class="black--text  font-weight-bold" v-if="!isCsr">This Scale is measured from 1-10</p>
            <v-row v-if="isCsr">
                <v-col cols="7" md="9" class="align-self-center">
                    <p class="text-subtitle-1 black--text  font-weight-bold">{{ !isMobile ? 'Choose the max number for the scale ' : 'Max Rating for Scale' }}</p>
                </v-col>
                <v-col cols="5" md="3">
                    <v-select v-model="selectedRating" label="Max" :items="[5, 7, 10]"></v-select>
                </v-col>
            </v-row>
        </div>
        <div class="question-content-scale mt-5" v-if="showScale">
            <v-row>
                <v-col class="py-0" cols="12" md="4">
                    <v-text-field-with-validation  v-model="label.min" rules="required"
									label="minLabel" />
                </v-col>
                <v-col class="py-0" cols="12" md="4">
                    <v-text-field-with-validation  v-model="label.median" rules="required"
									label="medLabel" />
                </v-col>
                <v-col class="py-0" cols="12" md="4">
                    <v-text-field-with-validation  v-model="label.max" rules="required"
									label="maxLabel" />
                </v-col>
            </v-row>
            <p class="black--text  font-weight-bold" :class="{'pt-1' : isMobile, 'pt-4' : !isMobile}"v-if="!isCsr">This Scale is measured from 1-10</p>
            <v-row v-if="isCsr">
                <v-col cols="7" md="9" class="align-self-center">
                    <p class="text-subtitle-1 black--text  font-weight-bold">{{ !isMobile ? 'Choose the max number for the scale ' : 'Max Rating for Scale' }}</p>
                </v-col>
                <v-col cols="5" md="3">
                    <v-select v-model="maxOpinionScale" label="Max" :items="[5, 7, 10]"></v-select>
                </v-col>
            </v-row>
        </div>
    </v-form>
 </validation-observer>
</template>
<script lang="ts" scoped>
import Vue, { PropType, VueConstructor } from "vue";
import { SurveyQuestionRecordItem } from "@/service";
import { UserType } from "@/config";
import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
import { QueryStoreInterface } from "@/store/query/types";

import VTextFieldWithValidation from "@/components/form/VTextFieldWithValidation.vue";
import {  ValidationObserver } from "vee-validate";
import ErrorLabel from "@/components/objects/ErrorLabel.vue";



const { mapGetters } = createHelpers<RootStoreInterface>();
const getters = mapGetters(["user"]);

const { mapMutations } = createHelpers<QueryStoreInterface>();
const mutations = mapMutations(["setConfigItems"]);



export default (Vue as VueConstructor<
	Vue & {
		$refs: {
			observer: InstanceType<typeof ValidationObserver>;
			errorLabel: InstanceType<typeof ErrorLabel>;
		};
	}
>).extend({
    components: {
        VTextFieldWithValidation,
        ValidationObserver,
        ErrorLabel
    },
    data: () => ({
        selectedOption: "",
        validationChoices: {},
        validationSettings: {},
        questionText: "",
        choice: "",
        choices: [{ id: "", text: "" }],
        label: {
            min: '',
            median: '',
            max: ''
        },
        endUserRating: '',
        csrRating: [],
        selectedRating: 0,
        maxOpinionScale: 0,
        showTextError: false,
        showTypeError: false,
        configDataItems: [] as any

    }),
    props: {
        configItems: {
            type: Array,
            default: [],
        },
        questionList: {
            type: Array,
            default: [],
        },
        activeQuestion: {
            type: Object as PropType<SurveyQuestionRecordItem>,
            default: {}
        },
        requiredTextError: {
            type: Boolean,
            default: false
        },
        requiredTypeError: {
            type: Boolean,
            default: false
        },
        questionType: {
            type: Object as PropType<Record<number, string>>,
            default: () => ({}),
        },
        getConfigValues: {
            type: Boolean,
            default: false
        },
        validateChecks: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...getters,
        configItemsText() {
        return this.configItems.map((item: any) => {
            let text = item.text;
            if (!this.isCsr && text == "Opinion Scale, 1-#" ) {
                text = 'Opinion Scale, 1-10';
            } else if (!this.isCsr && text == "Rating, 1-#") {
                text = 'Rating, 1-10'
            }
            return text;
        });
    },
        showChoices(): Boolean {
            return (this.selectedOption === "Multiple Choice" || this.selectedOption == "Ranking");
        },
        showRating(): Boolean {
            return (this.selectedOption == "Rating, 1-#" || this.selectedOption == "Rating, 1-10");
        },
        isCsr(): boolean {
            return this.user?.userType == UserType.CSR;
        },
        showScale(): Boolean {
            return (this.selectedOption == "Opinion Scale, 1-#" || this.selectedOption == "Opinion Scale, 1-10");
        },
        isMobile(): boolean {
            return this.$vuetify.breakpoint.smAndDown;
        }
    },
    methods: {
        ...mutations,
        async validateForm() {
            await this.$refs.observer.validate()
            this.$emit('reverse-error-state')
        },
        updateValidationChoice() {
            if(this.isMobile) this.getConfigData();
            
            let selectedData = this.selectedOption;
            if(this.selectedOption =="Opinion Scale, 1-10") {
                selectedData = "Opinion Scale, 1-#"
            }else if (this.selectedOption =="Rating, 1-10") {
                selectedData = "Rating, 1-#"
            }
            this.$emit('selected-option', selectedData);
            this.$emit('create-question', this.questionText);

            this.clearQuestionCategories();

            const selectedConfig = this.configItems.find((config: any) => config.text === selectedData);
            this.validationChoices = selectedConfig ? selectedConfig.validations : {};
            if (selectedConfig && selectedConfig?.endUserSelects) {
                this.endUserRating = selectedConfig.endUserSelects;

            }
            this.initValidations();
        },
        updateQuestionText() {
            if(this.isMobile) this.getConfigData();

            let selectedData = this.selectedOption;
            if(this.selectedOption =="Opinion Scale, 1-10") {
                selectedData = "Opinion Scale, 1-#"
            }else if (this.selectedOption =="Rating, 1-10") {
                selectedData = "Rating, 1-#"
            }
            this.$emit('create-question', this.questionText);
            this.$emit('selected-option', selectedData);
        },
        initValidations() {
            this.validationSettings = Object.keys(this.validationChoices).reduce((acc, key) => {
                if (key == "required") {
                    acc[key] = true;
                } else {
                    acc[key] = false;
                }
                // this.updateValidationSettings(this.validationRes);
                return acc;
            }, {} as Record<string, boolean>);
            this.getConfigData();
        },
        generateUniqueId() {
            return Date.now().toString();
        },
        addChoice() {
            const newId = this.generateUniqueId();
            this.choices.push({ id: newId, text: "" });

            this.$nextTick(() => {
        // Focus on the newly added input field
        if(this.isMobile) {
            const lastChoiceIndex = this.choices.length - 1;
            const lastChoiceRef = this.$refs[`choice-${lastChoiceIndex}`];
            if (lastChoiceRef) {
                lastChoiceRef[0].focus();
        }
        }
    });
        },
        reduceChoice(index: number) {
            this.choices.splice(index, 1);
        },
        sanitizeValue(key: string) {
            switch (key) {
                case "required":
                    return "Make Question Required";
                case "addOther":
                    return "Add 'Other' Choice";
                case "enableMultiSelect":
                    return "Enable Multiple Selection";
                case "randomizeChoice":
                    return "Randomize Choice Order";
                default:
                    return key.replace(/([a-z])([A-Z])/g, '$1 $2')
                        .replace(/\b\w/g, char => char.toUpperCase());
            }
        },
        updateSelectedOption() {
            const questionTypeId = this.activeQuestion?.questionTypeId;
            if (questionTypeId) {
                this.selectedOption = this.questionType[questionTypeId];
                if(!this.isCsr && this.selectedOption == "Opinion Scale, 1-#" ) {
                    this.selectedOption = 'Opinion Scale, 1-10'
                } else if(!this.isCsr && this.selectedOption == "Rating, 1-#" ) {
                    this.selectedOption = 'Rating, 1-10';
                }
                this.updateValidationChoice();
            }
        },
        getConfigData(newQuestionConfig?: any) {
            this.configDataItems = !newQuestionConfig ? [{ validation: this.validationSettings }, { choices: this.choices }, { label: this.label }, { selectedRating: this.selectedRating }, { maxOpinionScale: this.maxOpinionScale }] : newQuestionConfig;
            if(this.isMobile) this.setConfigItems(this.configDataItems);

            return this.configDataItems;
        },
        updateValidationSettings(response: any) {
            // update pre-filled  validation through  DB
            const parseRes = JSON.parse(response);
            if(Object.keys(parseRes).length > 0) this.getConfigData(parseRes);
            if (response && response.length > 0) {
                const validationFromDB = parseRes[0].validation;

                for (const key in this.validationSettings) {

                    if (validationFromDB.hasOwnProperty(key)) {
                        this.validationSettings[key] = validationFromDB[key];
                    }
                }
            }
            this.udpateQuestionCategories(parseRes);
        },
        udpateQuestionCategories(parseRes: any) {
            if (parseRes[0].choices) {
                this.choices = parseRes[0].choices;
            } else if (parseRes[0].label) {
                if (parseRes[0].maxOpinionScale) {
                    this.maxOpinionScale = parseRes[0].maxOpinionScale;
                }
                this.label = parseRes[0].label;
            } else if (parseRes[0].selectedRating) {
                this.selectedRating = parseRes[0].selectedRating;
            }
        },
        clearQuestionContent() {
            this.questionText = "";
            this.selectedOption = "";
            this.$emit('selected-option', this.selectedOption);
            this.$emit('create-question', this.questionText);
            this.clearQuestionCategories();
        },
        clearQuestionCategories() {
            this.choices = [{ id: "", text: "" }];
            this.label = {
                min: '',
                median: '',
                max: ''
            };
            this.csrRating = [];
        },
    },
    watch: {
        activeQuestion(newVal: SurveyQuestionRecordItem) {
            if (newVal) {

                if (Object.keys(newVal).length == 0) {
                    this.clearQuestionContent();
                    return;
                }
                this.questionText = newVal.text as string;
                this.updateSelectedOption();
                this.updateValidationSettings(newVal.configuration);
            }
        },
        requiredTextError(newVal: boolean) {
            if (newVal) {
                this.showTextError = true;
            } else {
                this.showTextError = false;

            }
        },
        requiredTypeError(newVal: boolean) {
            if (newVal) {
                this.showTypeError = true;
            } else {
                this.showTypeError = false;

            }
        },
        questionText(newVal: string) {
            if (newVal.length == 0) {
                this.$emit('create-question', this.questionText);
            } else if (newVal) {
                this.showTextError = false;
            }   
        },
        selectedOption(newVal: string) {
            if (newVal) {
                this.showTypeError = false;
            }
        },
        validateChecks(newVal: Boolean) {
            if(newVal) {
                this.validateForm();
            }
        }
    },
    mounted() {
        this.getConfigData();
        this.choices[0].id = this.generateUniqueId();
        this.$nextTick(() => {
            if(this.isMobile && Object.keys(this.activeQuestion).length != 0) {
                this.questionText = this.activeQuestion.text as string;
                this.updateSelectedOption();
                this.updateValidationSettings(this.activeQuestion.configuration);
            }
        })
    }
})
</script>
<style lang="scss" scoped>
:deep(textarea) {
    padding: 10px;
    position: relative;
}
.question-input-field :deep(.v-text-field__slot:after) {
    content: "Question";
    position: absolute;
    font-weight: bold;
    bottom: -28px;
    left: 10px;
    font-size: 14px;
    background-color: transparent;
    border-style: none;
    transform: scaleX(1);
    color: rgba(0, 0, 0, 0.6);
}

:deep(.v-input__control > .v-input__slot > .v-text-field__slot > label.v-label) {
    top: 5px;
    left: 10px !important;
    z-index: 2;
    color: rgb(18 18 18 / 45%) !important;

    &.v-label--active {
        left: 0 !important;
        top: 0;
        color: var(--v-primary-base) !important;
    }
}

.question-content :deep(.v-input__slot),
.question-type :deep(.v-input__slot),
.question-content-rating :deep(.v-input__slot),
.question-content-scale :deep(.v-input__slot) {
    &::before {
        width: 100% !important;
    }
}

.error-text :deep(.v-input__control > .v-input__slot > .v-text-field__slot > label.v-label),
.input-question-field.error-text .v-input__control>.v-input__slot>.v-text-field__slot>label.v-label--active,
p.error-text,
.question-input-field.error-text :deep(.v-text-field__slot:after),
.error-text :deep(.v-input__label) {
    color: red !important;
}

:deep(.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset) {
    border-color: var(--v-primary-base);
}

:deep(.error-text.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset) {
    border-color: red;
}

.validation-list {
    max-height: 40px;
}

:dee(.v-messages.theme--light) {
    display: none;
}

@media (max-width: 960px) {
    .v-menu__content, :deep(.v-menu__content) {
        border-radius: 10px !important;
    }

    .question-content-list  {
        max-width: 100%;
    }

    .question-content-rating , .question-content-scale {
        .row  {
            max-width: 100%;
        }
    }

    .builder-submission-form {
        :deep(.v-messages__message) {
            margin-bottom: 10px;
        }
    }
}
</style>
