<template>
  <o-sheet class="query-index-card" :border="color" :borderWidth="1"
    :class="{ active: active, [`${color}--text`]: true }">
    <div :class="{ 'title-row': !mobile }">
      <div class="d-flex align-baseline" :style="{
    'flex-direction': mobile ? 'row-reverse' : 'row',
    'justify-content': mobile ? 'space-between' : 'start'
  }">
        <div v-if="mobile" class="ref-id ml-4">(RefId: {{ query.queryId }})</div>
        <div class="discussion-datetime-text font-weight-bold t-3">
          {{ query.displayTime.substring(0, 10) }}
          <span v-if="!mobile" class="ref-id">(RefId: {{ query.queryId }})</span>
        </div>
      </div>
      <v-spacer v-if="!mobile" />
      <div class="d-flex flex-row">
        <div class="mr-2 my-auto">
          <v-icon color="primary">{{ mediaTypeIcon }} </v-icon>
        </div>
        <div class="t-5 font-weight-bold">
          {{ title }}
        </div>
      </div>
    </div>
    <!-- wrapper to get buttons in the right column -->
    <div :class="{ 'query-card-content': true, 'qcc-mobile': mobile }">
      <!-- Diagnosis, context, plan/pool -->
      <div :style="{ flex: mobile ? 0 : 1 }">
        <div class="t-3 mt-2 font-weight-bold">{{ query.diagnosisName }}</div>
        <div class="t-3" v-if="query.contextName && query.contextName.trim() != ''">
          Focus: {{ query.contextName }}
        </div>
        <div v-if="query.planPoolName" class="t-3">
          {{ query.planPoolName }}
        </div>
        <div class="t-3 preview-text mt-2 pe-2">{{ questionCountDisplay }} {{ query.previewText }}</div>
      </div>
      <div v-if="!isMobile"
        :class="{ 'd-flex': true, 'flex-row': mobile, 'flex-column': !mobile, 'justify-space-around': true, 'mt-2': mobile }">
        <o-btn :variant="color" @click="$emit('respond')">{{ $t("discussion.index.caption.btnOpen") }}</o-btn>
      </div>
    </div>
  </o-sheet>
</template>

<script lang="ts">
import { QueryIndexFullDisplay, QuerySearchDisplayItem } from "@/service";
import Vue, { PropType } from "vue";
import OBtn from "@/framework/OBtn.vue";
import OSheet from "@/framework/OSheet.vue";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";

const { mapGetters } = createHelpers<RootStoreInterface>();
const getters = mapGetters(["user"]);

export default Vue.extend({
  components: {
    OSheet,
    OBtn
  },
  data: () => ({

  }),
  props: {
    query: {
      type: Object as PropType<QueryIndexFullDisplay>,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...getters,
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
    color(): string {
      // Based on type/status:
      // Invitations - primary
      // Upcoming? - secondary
      // Ongoing - tertiary
      if (this.query.isInvited) {
        return "primary";
      }
      if ((this.query.meetingTime ?? 0) > Date.now() / 1000) {
        return "secondary";
      }
      return "tertiary";
    },
    title(): string {
      if (this.query.isInvited) {
        return this.$t("discussion.index.title.invitation") as string;
      }

      if (this.query.mediaType !== QuerySearchDisplayItem.mediaType.MESSENGER) {
        if (this.query.meetingTimeDisplay) {
          return this.query.meetingTimeDisplay;
        } else {
          return this.$t("discussion.index.title.toBeScheduled") as string;
        }
      }

      if (this.query.status == QuerySearchDisplayItem.status.PENDING_SURVEY || (!!this.query.surveyStatus && this.query.surveyStatus.length)) {
        if (!!this.query.surveyStatus && ["new", "post_pending", "followup_pending"].includes(this.query.surveyStatus)) {
          return this.$t(`discussion.index.title.survey.${this.query.surveyStatus}`) as string;
        }
        return this.$t("discussion.index.title.pendingSurvey") as string;
      }

      if (!this.query.isUpdated && this.query.responseCount > 0) {
        return this.$t("discussion.index.title.noResponses") as string;
      }

      return this.$t(`discussion.index.title.${this.query.isUpdated ? "updated" : "noUpdates"}`) as string;
    },
    mediaTypeIcon(): string {
      switch (this.query.mediaType) {
        case QuerySearchDisplayItem.mediaType.MESSENGER:
          return "$textAttachment";
        case QuerySearchDisplayItem.mediaType.VIDEO:
          return "$videoAttachment";
        case QuerySearchDisplayItem.mediaType.AUDIO:
          return "$audioAttachment";
        default:
          return "";
      }
    },
    isVideoQuery(): boolean {
      return this.query.mediaType == QuerySearchDisplayItem.mediaType.VIDEO
    },
    questionCountDisplay():string{
      let retVal = '';

      if (this.query && this.query.questionCount > 0){
        retVal = this.query?.questionCount + ' ' + 'Question';

        // Append 's ' if questionCount is not equal to 1, otherwise just a space
        retVal += this.query.questionCount !== 1 ? 's - ' : ' - ';
      }
      return retVal;
    }
  },
});
</script>

<style lang="scss" scoped>
.query-index-card {
  background-color: #ffffff;
  width: 100%;
  max-width: 595px;
  min-height: 182px;


  &.active {
    background-color: #f0f0f0;
  }

  .title-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
  }

  .ref-id {
    font-size: 16px;
    color: #888;
  }

  .query-card-content {
    display: flex;
    flex-direction: row;

    &.qcc-mobile {
      flex-direction: column;
    }

    .preview-text {
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media (min-width: 1164px) and (max-width: 1419.6px) {
  .query-index-card {

    .t-5  {
      font-size: 16px !important;
    }
    .t-3 {
      font-size: 14px !important;
    }

    .query-card-content .v-btn {
     font-family: 'Cera Pro', 'Arial', 'sans-serif';
     border-radius: 7px;
     height: 30px;
     padding: 7.5px 15px;
     font-size: 0.75rem;
    }
  }

  .discussion-datetime-text {
    display: none;
  }
}
</style>
