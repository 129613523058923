<comment>
  CmsRichTextCard is to use with prefetched document and to present as card (o-sheet) style view
</comment>
<template>
  <o-sheet class="overflow-hidden" v-bind="$attrs">
    <v-overlay v-if="overlay" absolute :value="loading">{{ overlayText }}</v-overlay>
    <slot name="title" :title="document ? document.data.title_text : undefined">
      <div :class="titleClass" v-if="document && document.data.title_text" v-text="document.data.title_text" />
    </slot>
    <prismic-rich-text
      v-if="document"
      :key="getCurrentTheme.name"
      :class="prismicClass"
      :field="content"
      :htmlSerializer="htmlSerializer"
    />
    <div v-if="!document && missing">Missing CMS Document.</div>
  </o-sheet>
</template>

<script lang="ts">
/*
    This is a simple rich-text content component which makes the following assumptions:
    1. The content document has a single rich text field named "content"
    2. If overlay is true there is a relative or absolutely positioned parent in which the overlay can be bound
    3. No assumption is made about the size of the control, and this will reflow based on content if not constrained

    This automatically replaces thematic text surrounded by :: - ::
    Current tags are:
      company
      domain
      infodomain

      :key in the tag above forces a refresh if the theme changes

*/
import Vue, { PropType } from "vue";
import OSheet from "@/framework/OSheet.vue";

import { createHelpers } from "vuex-typescript-interface";
import { ThemeStoreInterface } from "@/store/theme/types";
import { RootStoreInterface } from "@/store/types";
import { Document } from "@prismicio/client/types/documents";

const { mapGetters: mapRootGetters } = createHelpers<RootStoreInterface>();
const { mapGetters } = createHelpers<ThemeStoreInterface>();
const getters = mapGetters(["getReplacementText"]);
const rootGetters = mapRootGetters(["currentLocale"]);
const { mapGetters: mapThemeGetters } = createHelpers<ThemeStoreInterface>();
const themeGetters = mapThemeGetters(["getCurrentTheme"]);

export default Vue.extend({
  components: {
    OSheet
  },
  data: () => ({
    loading: false,
    missing: false
  }),
  props: {
    document: {
      type: Object as PropType<Document>,
      required: false
    },
    titleClass: {
      type: String,
      default: "text-h5"
    },
    bodyClass: {
      type: String,
      default: ""
    },
    // If overlay is true there should be a relative or absolute parent
    overlay: {
      type: Boolean,
      default: false
    },
    overlayText: {
      type: String,
      default: "Loading..."
    },
    // Provide content field from which content should be parsed and shown.
    // Default is "content".
    contentField: {
      type: String,
      default: "content"
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...getters,
    ...rootGetters,
    ...themeGetters,
    isXs(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.xs;
    },
    mobileContentField(): string {
      return `${this.contentField}_mobile`;
    },
    content(): any {
      if (this.isXs && !!(this.document?.data[this.mobileContentField])) {
        return this.document?.data[this.mobileContentField];
      } 
      return this.document?.data[this.contentField];
    },
    prismicClass(): string {
      return this.bodyClass.length ? this.bodyClass : "prismic" + (this.isMobile ? " prismic-mobile" : "");
    }
  },
  methods: {
    htmlSerializer(type: string, _element: any, content: string, _children: string[]) {
      // Text is in spans
      // Default span implementation does a nl2br
      if (type == "span") {
        return content ? this.patternMatcher(content).replace(/\n/g, "<br />") : "";
      }
      return null;
    },
    patternMatcher(content: string): string {
      return content.replace(/::([^:]+)::/g, (_m, s) => {
        return this.getReplacementText(s);
      });
    }
  },
  watch: {
    content(val) {
      this.$emit("loaded", Boolean(val), this.document);
    }
  },
  created() {
    if (Boolean(this.content)) {
      this.$emit("loaded", true);
    }
  }
});
</script>

<style lang="scss" scoped>
.o-sheet {
  padding: 0px;

  .prismic {
    height: 100%;

    :deep(p) {
      margin: 0px;
      height: 100%;

      img {
        width: 100%;
      }
    }
  }
  .prismic-mobile {
    margin-block-end: -0.5em;
  }
}
</style>
<!-- handle prismic dynamic dom card styling for end user dashboard -->
<style lang="scss">
.container-md.medium-screen,
.container-sm.medium-screen {
  .prismic {
    a {
      max-width: 500px;
      height: 160px;
      display: block;
    }
  }

}
</style>
