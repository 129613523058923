<comments>
  This displays/edits DateTimes that are stired as unix timestamps.
    The v-model should be a number or null.
    Since the date picker uses ISO that is stored internally and watched, and the text fields used a locale formatted date string.
</comments>

<template>
  <v-menu
    ref="menu"
    v-model="visible"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <ValidationProvider ref="provider" :name="label" :vid="vid" :rules="rules" v-slot="{ errors, valid }">
        <v-text-field
          v-model="dateFormatted"
          prepend-icon="$calendar"
          autocomplete="off"
          :minMax="minMax ? minMax : undefined"
          :id="label"
          :label="$t(`fields.${label}`)"
          :hint="hint ? $t(`${hint}`) : undefined"
          :placeholder="placeholder ? $t(`placeholders.${placeholder}`) : undefined"
          :persistent-hint="persistentHint"
          :error-messages="errors"
          :success="Boolean(rules) && Boolean(dateFormatted) && valid"
          :filled="filled"
          :clearable="clearable"
          :readonly="!editableText || readonly"
          :hide-details="!errors.length && hideDetails"
          v-bind="attrs"
          x-blur="inverValue=parseTextDate(dateFormatted);"
          @focus="showMenu"
          @click="showMenu(false)"
        />
      </ValidationProvider>
    </template>
    <v-date-picker v-model="innerValue" no-title @input="visible=false" :min="minDate" :max="maxDate" :readonly="readonly"></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { ValidationProvider } from "vee-validate";
import { DateTime } from "luxon";
// TODO Make text entry editable

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      provider: InstanceType<typeof ValidationProvider>;
    };
  }
>).extend({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: "" as string | null,
    textValue: "" as string | null,
    // dateFormatted: "",
    visible: false,
    firstFocus: true
  }),
  props: {
    // must be included in props
    // Expects unix timestamp
    value: {
      type: Number,
      required: false
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    minMax: {
      type: String,
      required: false
    },
    // To set minimum date
    min: {
      type: Number,
      required: false
    },
    // To set maximum date
    max: {
      type: Number,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    persistentHint: {
      type: Boolean,
      required: false,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    // To allow user to type date manually in text field
    editableText: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // Settable value for textfield. Set is required for clearable fields
    dateFormatted: {
      get(): string | null {
        return this.textValue;
      },
      set(value: string | null) {
        this.textValue = value;
        if (value && !this.parseTextDate(value)) {
          this.$emit("input", 0);
        } else {
          this.innerValue = this.parseTextDate(value);
        }
      }
    },
    minDate(): string | undefined {
      return this.parseDate(this.min) || undefined;
    },
    maxDate(): string | undefined {
      return this.parseDate(this.max) || undefined;
    }
  },
  methods: {
    // Convert unix timestamp to locale formatted date
    formatDate(date: number | null): string {
      if (!date) return "";
      return DateTime.fromSeconds(date).toFormat("MM/dd/yyyy");
    },
    // Connvert unix timestamp to iso date for the date picker
    parseDate(date: number | null) {
      if (!date) return null;
      // If Max Date in range, set to 23:59:59
      if (this.minMax === "max") {
        return DateTime.fromSeconds(date).set({ hour: 23, minute: 59, second: 59 }).toISO();
      }
      // Otherwise, set to 0
      return DateTime.fromSeconds(date).set({ hour: 0, minute: 0, second: 0 }).toISO();
    },
    parseTextDate(date: string | null): string | null {
      if (!date) return null;
      return DateTime.fromFormat(date, "MM/dd/yyyy")?.toISODate();
    },
    checkValue(str: string, max: number): string {
      if (str.charAt(0) !== '0' || str == '00') {
        let num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    showMenu(c: boolean) {
      if (!c && this.visible) return;
      this.visible = true;
      if (this.firstFocus) {
        this.firstFocus = false;
        setTimeout(() => {
          this.visible = true;
        }, 100);
      }
    }
  },
  watch: {
    // Reset inner value on show
    visible(val: boolean) {
      if (val) {
        this.innerValue = this.value ? this.parseDate(this.value) : null;
      }
    },
    // Handles internal model changes from datepicker
    innerValue(newVal) {
      this.$emit("input", newVal ? DateTime.fromISO(newVal).toSeconds() : null);
    },
    // Handles external model changes.
    value(newVal) {
      if (newVal != 0) {
        this.innerValue = isNaN(newVal) ? "" : this.parseDate(newVal);
        this.textValue = isNaN(newVal) ? "" : this.formatDate(newVal);
      }
    },
    textValue(val: string | null) {
      if (!val) return;
      let input = val;
      if (/\D\/$/.test(input)) input = input.substring(0, input.length - 3);
      let values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = this.checkValue(values[0], 12);
      if (values[1]) values[1] = this.checkValue(values[1], 31);
      let output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      this.textValue = output.join('').substring(0, 14);
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.parseDate(this.value);
      this.textValue = isNaN(this.value) ? "" : this.formatDate(this.value);
    }
  }
});
</script>
