import { render, staticRenderFns } from "./QuestionBuilderActionContent.vue?vue&type=template&id=48a65f8a&scoped=true"
import script from "./QuestionBuilderActionContent.vue?vue&type=script&lang=ts&scoped=true"
export * from "./QuestionBuilderActionContent.vue?vue&type=script&lang=ts&scoped=true"
import style0 from "./QuestionBuilderActionContent.vue?vue&type=style&index=0&id=48a65f8a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a65f8a",
  null
  
)

export default component.exports