<template>
  <two-column-flow-layout :mobileWidth="1164">
    <template v-slot:left="{ isMobile }">
      <div class="mfu-column mx-auto right my-auto">
        <div class="d-flex mb-4 ml-4 justify-space-between">
          <div v-if="!mobileOnly" class="text-h4 primary--text font-weight-bold">Welcome {{ userName }}</div>
          <div v-if="isMobile && !mobileOnly" class="text-right">
            <o-btn v-if="isLoggedIn && !isCsr && userCanPost" :to="{ name: 'NewQuery' }">
              {{ $t('captions.askQuestion') }}
            </o-btn>
          </div>
        </div>
        <v-row>
          <v-col cols="12" class="container-lg" :class="{'pa-0 ma-3' : mediumScreen}" :style="!isMobile ? mediumScreen ? '' : 'min-height:315px;' : undefined">
            <upcoming-discussions-card :style="!isMobile ? 'height: 290px' : undefined" :userId="userId" :isMobile="isMobile" :mobileOnly="mobileOnly" :isExpert="isExpert"/>
          </v-col>
        </v-row>
        <div v-if="isMobile && mobileOnly && isLoggedIn && !isCsr && userCanPost" class="d-flex container-lg flex-column mt-8">
          <o-btn :to="{ name: 'NewQuery' }">{{ $t('captions.askQuestion') }}</o-btn>
        </div>
        <v-row class="mt-6">
          <v-col cols="12" sm="6" class="container-sm" :class="{'medium-screen me-0 pa-0 ma-3' : mediumScreen}" v-show="cmsTiles.tile1">
            <cms-rich-tile-card contentField="tile_1_content" :document="currentDocument" :height="cmsHeight" :isMobile="isMobile" @loaded="cmsTiles.tile1 = $event" />
          </v-col>
          <v-col cols="12" sm="6" class="container-sm" :class="[isXs ? 'mt-2' : '', mediumScreen ? 'medium-screen pa-0 ma-3' : '']" v-show="cmsTiles.tile2">
            <cms-rich-tile-card contentField="tile_2_content" :document="currentDocument" :height="cmsHeight" :isMobile="isMobile" @loaded="cmsTiles.tile2 = $event" />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:right="{ isMobile }">
      <div :class="{ 'mfu-column mx-auto left': true, 'mt-1': !isMobile, 'mt-14': !isMobile && !userCanPost, 'mt-11': !cmsTiles.tile3 && !userCanPost, 'mt-8': isMobile }">
        <div v-if="!isMobile" class="text-right mb-4">
          <o-btn v-if="isLoggedIn && !isCsr && userCanPost" :to="{ name: 'NewQuery' }">
            {{ $t('captions.askQuestion') }}
          </o-btn>
        </div>
        <v-row>
          <v-col cols="12" class="container-md" :class="{'medium-screen pa-0 ma-3' : mediumScreen}" v-show="cmsTiles.tile3">
            <cms-rich-tile-card contentField="tile_3_content" :height="cmsHeight" :document="currentDocument" :isMobile="isMobile" @loaded="cmsTiles.tile3 = $event" />
          </v-col>
        </v-row>
        <v-row :class="{ 'mt-6': cmsTiles.tile3, 'mt-3': !cmsTiles.tile3 && userCanPost}">
          <v-col class="container-lg" :class="{'pa-0 ma-3' : mediumScreen}" cols="12">
            <query-stats-card :userId="userId" :isExpert="isExpert" />
          </v-col>
        </v-row>
      </div>
    </template>
  </two-column-flow-layout>
</template>

<script lang="ts">
import Vue from "vue";
import TwoColumnFlowLayout from "@/components/layout/TwoColumnFlowLayout.vue";
import UpcomingDiscussionsCard from "./components/UpcomingDiscussionsCard.vue";
import QueryStatsCard from "./components/QueryStatsCard.vue";
import CmsRichTileCard from "@/components/util/CmsRichTileCard.vue";
import CommonButton from "@/components/form/CommonButton.vue";
import OBtn from "@/framework/OBtn.vue";
import { UserType } from "@/config";

import { RootStoreInterface } from "@/store/types";
import { DashboardStoreInterface } from "@/store/dashboard/types";
import { createHelpers } from "vuex-typescript-interface";

const { mapGetters } = createHelpers<RootStoreInterface>();
const { mapGetters: mapDocGetters, mapActions: mapDocActions } = createHelpers<DashboardStoreInterface>();

const getters = mapGetters(["user", "userTypes", "currentLocale", "isLoggedIn", "activeUserType", "userCanPost"]);
const docGetters = mapDocGetters(["currentDocument"])
const docActions = mapDocActions(["loadCms"])

export default Vue.extend({
  components: {
    TwoColumnFlowLayout,
    UpcomingDiscussionsCard,
    QueryStatsCard,
    CmsRichTileCard,
    CommonButton,
    OBtn
  },
  data: () => ({
    docType: "onviv-portal-dashboard",
    cmsTiles: {
      tile1: false,
      tile2: false,
      tile3: false
    }
  }),
  computed: {
    ...getters,
    ...docGetters,
    userId(): number {
      return this.user?.id ?? 0;
    },
    isExpert(): boolean{
      return this.user?.noeticExpert ?? false;
    },
    isCsr(): boolean {
      return this.activeUserType == UserType.CSR;
    },
    userName(): string {
      return this.user?.resolvedDisplayName ?? "";
    },
    isXs(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.width < 1420;
    },
    mobileOnly(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
    cmsHeight(): number | undefined {
      return !this.isMobile ? this.mediumScreen ? 220 : 210 : undefined;
    },
    mediumScreen(): boolean {
      return this.$vuetify.breakpoint.width >= 1164 && this.$vuetify.breakpoint.width <=  1419;
    }
  },
  methods: {
    ...docActions
  },
  created() {
    this.loadCms(this.docType);
  }
});
</script>


<style lang="scss" scoped>
:deep(.tcf) {
  @media only screen and (min-width: 1420px) and (min-height: 750px) {
    top: 50% !important;
    margin-top: -300px !important;
  }
}
.mfu-column {
  height: 100%;
  position: relative;
  padding: 0 20px;

  @media only screen and (min-width: 768px) {
    width: 752px;
  }

  @media only screen and (min-width: 1420px) {
    width: 694px;

    &.left {
      margin-left: 0px !important;
      margin-right: auto !important;
    }
    &.right {
      margin-left: auto !important;
      margin-right: 0px !important;
    }
  }
}

@media (min-width: 1164px) and (max-width: 1419.6px)  {
  .container-lg {
    max-width: 500px;
    width: 100%;
    min-height: 220px;
  }

  .container-md {
    max-width: 500px;
    min-height: 160px;
  }

  .container-sm {
    max-width: 244px;
    max-height: 160px;
  }

  .mfu-column {
    max-width: 100%;
  }
}
</style>
