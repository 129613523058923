/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Module } from "vuex-typescript-interface";
import { QueryHeaderType, QueryStoreInterface, QueryResponseType } from "./types";
import { RootStoreInterface } from "../types";
import { InteractionService } from "@/service/services/InteractionService";
import { Mutex } from "async-mutex";
import { Buffer } from "buffer";

// Code-gen doesnt handle form input correctly for file upload
import { request as __request } from "@/service/core/request";
import { QueryAttachmentInfo } from "@/service/models/QueryAttachmentInfo";
import { TermsParameterType } from "../terms/types";
import { QueryArchiveInfo, QueryInteractionDisplayItem } from "@/service";

const loadMutex = new Mutex();

const endUserResponseTypes = [
  "response",
  "attachment",
  "csrupdateaccept",
  // "attachment_inherited" - These show as attachments only
  // These only occur during HOLD and so only seen by CSR and owner
  "csrhold",
  "csrupdate",
  "csrupdatedecline"
];

const query: Module<QueryStoreInterface, RootStoreInterface> = {
  state: {
    query: null,
    responses: [] as QueryResponseType[],
    //Participants and color index
    participantIds: [] as number[], // Used for colors
    participantColorIndex: Object.create(null) as Record<string, number>,
    lastResponseId: 0,
    moreRecords: true,
    responseDraftCacheId: null,
    queryBuilderConfigItems: [] as any
  },

  getters: {
    currentQuery: state => state.query,
    loadedResponses: state => state.responses,
    surveyInfo: state => state.query?.survey ?? null,
    colorIndex: state => state.participantColorIndex,
    endUserResponseTypes: () => endUserResponseTypes,
    hasMoreRecords: state => state.moreRecords,
    responseDraftId: state => state.responseDraftCacheId,
    getConfigItems: state => state.queryBuilderConfigItems,
  },

  mutations: {
    setQuery(state, q): void {
      state.query = q;
    },
    setResponses(state, r: QueryResponseType[]): void {
      state.responses = r;
    },
    addResponses(state, r: QueryResponseType[]): void {
      state.responses.push(...r);
    },
    setConfigItems(state, r: any[]): void {
      state.queryBuilderConfigItems = r;
    },
    clearParticipants(state): void {
      state.participantIds = [];
      state.participantColorIndex = Object.create(null);
    },

    setParticipants(state, { ids, colorMap }: { ids: number[]; colorMap: Record<string, number> }): void {
      state.participantColorIndex = colorMap;
      state.participantIds = ids;
    },

    setLastResponseId(state, id: number): void {
      state.lastResponseId = id;
    },
    setMoreRecords(state, b: boolean): void {
      state.moreRecords = b;
    },
    clearQueryData(state): void {
      // Return to a fresh state
      state.query = null;
      state.participantIds = [];
      state.participantColorIndex = Object.create(null);
      state.responses = [];
      state.moreRecords = true;
      state.lastResponseId = 0;
      state.responseDraftCacheId = null;
    },
    clearResponseDraft(state): void {
      if (state.responseDraftCacheId && state.responseDraftCacheId.length) {
        localStorage.removeItem(state.responseDraftCacheId);
      }
    },
  },

  actions: {
    async loadQuery({ commit, dispatch }, qid): Promise<QueryHeaderType> {
      commit("setQuery", null);
      commit("setLastResponseId", 0);
      commit("setResponses", []);
      commit("setMoreRecords", true);
      dispatch("generateResponseDraftId", String(qid));
      // commit("setL")
      commit("clearParticipants");
      const r = await InteractionService.getDiscussion({ key: String(qid) });
      commit("setQuery", r);
      return r;
    },

    async updateQuery({ commit }, qid): Promise<QueryHeaderType> {
      const r = await InteractionService.getDiscussion({ key: String(qid) });
      commit("setQuery", r);
      return r;
    },

    async loadResponses({ commit, state }, pageSize = 20): Promise<QueryResponseType[]> {
      return loadMutex.runExclusive(async () => {
        if (!state.query?.key) {
          throw new Error("No Query Selected");
        }
        const r = await InteractionService.getDiscussionResponses({
          key: state.query.key,
          afterId: state.lastResponseId,
          page: 1,
          pageSize
        });
        if (!r) return [];  // return if there is no body
        // Update participants and color map
        if (r.data && r.data.length) {
          const newParticipants = [...state.participantIds];
          const colorMap = { ...state.participantColorIndex };
          let lastId = state.lastResponseId;

          r.data.forEach(r => {
            if (r.queryExpertId && endUserResponseTypes.includes(r.type)) {
              if (!newParticipants.includes(r.queryExpertId)) {
                colorMap[String(r.queryExpertId)] = newParticipants.length;
                newParticipants.push(r.queryExpertId);
              }
            }
            lastId = Math.max(lastId, r.id);
          });
          // Update the color Index map
          commit("setParticipants", { ids: newParticipants, colorMap });
          commit("addResponses", r.data);
          commit("setLastResponseId", lastId);
        }
        commit("setMoreRecords", (r.meta?.pagination?.total_pages ?? 0) > (r.meta?.pagination?.current_page ?? 1));
        // console.log(r.meta);
        return r.data ?? [];
      });
    },

    async postTextResponse({ state, commit }, { text, terms }: { text: string; terms?: TermsParameterType }): Promise<void> {
      if (!state.query) {
        throw new Error("No Query Selected");
      }
      // console.log(terms);
      const _r = await InteractionService.postResponse({ key: state.query.key, requestBody: { text, terms } });
      commit("clearResponseDraft");
      // await RepositoryBase.post(`/appapi/v1/query/${state.query.key}/response`, {
      //   text
      // });
    },
    async generateResponseDraftId({ state, rootGetters }, qid: string): Promise<void> {
      if (rootGetters.user && qid && qid.length) {
        state.responseDraftCacheId = `resp-${rootGetters.user.id}-${qid}`;
      }
    },
    async saveDraftResponse({ state, getters }, text: string): Promise<void> {
      if (!state.query) {
        throw new Error("No Query Selected");
      }
      if (getters.responseDraftId && getters.responseDraftId.length) {
        localStorage.setItem(getters.responseDraftId, Buffer.from(text).toString('base64'));
      }

      // TODO Implement save Draft using api
      // try {
      //   await InteractionService.
      // }
      // RepositoryBase.post(`/appapi/v1/query/${state.query.key}/saveDraft`, {
      //   text
      // });
    },
    async getDraftResponse({ getters, commit }): Promise<string | undefined> {
      // Check query status
      if (getters.currentQuery && (getters.currentQuery.status == QueryInteractionDisplayItem.status.CLOSED || getters.currentQuery.status == QueryInteractionDisplayItem.status.CANCELLED)) {
        // Clear draft if query is closed/cancelled
        commit("clearResponseDraft");
      } else if (getters.responseDraftId && getters.responseDraftId.length) {
        const text = localStorage.getItem(getters.responseDraftId);
        if (text && text.length) {
          return Buffer.from(text, 'base64').toString('ascii');
        }
      }
      return undefined;
    },

    async postAttachmentResponse(
      { state },
      {
        text,
        description,
        file,
        _terms
      }: { text: string; description: string; file: File; _terms?: TermsParameterType }
    ): Promise<void> {
      if (!state.query) {
        throw new Error("No Query Selected");
      }

      const _result = await __request({
        method: "POST",
        path: `/user/interaction/${state.query.key}/attachment`,
        formData: { text, description, file }
      });
      // return result.body;

      // // TODO terms??
      // const formData = new FormData();

      // // files
      // formData.append("file", file, file.name);

      // // additional data
      // formData.append("desc", description);
      // if (text) {
      //   formData.append("comment", text);
      // }
      // await RepositoryBase.post(`/appapi/v1/query/${state.query.key}/responseFile`, formData);
    },
    async getAttachmentInfo({ state }, id: number | string): Promise<QueryAttachmentInfo> {
      if (!state.query) {
        throw new Error("No Query Selected");
      }

      return InteractionService.getAttachmentInfo({ key: state.query.key, id: id.toString() });
    },

    async getArchiveInfo({ state }, id: number | string): Promise<QueryArchiveInfo> {
      if (!state.query) {
        throw new Error("No Query Selected");
      }

      return InteractionService.getArchiveInfo({ key: state.query.key, id: id.toString() });
    },

    async acceptInvitation({ state, dispatch }, payload: { timestamp?: number; unpaid: boolean }): Promise<boolean> {
      if (!state.query) {
        throw new Error("No Query Selected");
      }
      const update = await InteractionService.acceptInvitation({
        key: state.query.key,
        requestBody: payload
      });
      dispatch("processDisplayUpdate", update, { root: true });
      return true;
    },
    async declineInvitation({ state, dispatch }): Promise<boolean> {
      if (!state.query) {
        throw new Error("No Query Selected");
      }
      try {
        const update = await InteractionService.declineInvitation({
          key: state.query.key
        });
        dispatch("processDisplayUpdate", update, { root: true });
        return true;
      } catch (e) {
        throw e;
      }
    }
  }
};

export default query;
